// @ts-strict-ignore
import Datepicker from './Datepicker'
import { Box, CleanButton, Text, breakpoints, selectStyles } from '@gassan-ui'
import { format } from 'date-fns'
import Downshift from 'downshift'
import { useTranslation } from 'next-i18next'
import { rgba } from 'polished'
import { FC, InputHTMLAttributes, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

const Menu = styled(Box)`
  white-space: nowrap;
  position: absolute;

  transform: translateY(100%);
  min-width: min-content;
  background: ${(p) => p.theme.colors.pampasLight};
  outline: none;
  border: 1px solid ${(p) => p.theme.colors.tabaccoBrown};
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.09);
  z-index: ${(p) => p.theme.zIndices.popOver};
  padding: 0.75rem;
  @media screen and (min-width: ${breakpoints.small}) {
    padding: 1.75rem;
  }
`

const DatepickerButton = styled(CleanButton)<{ isFilled: boolean; status: string }>`
  ${selectStyles};
  color: ${(p) => (p.isFilled ? p.theme.colors.shades[800] : p.theme.colors.shades[500])};
  display: flex;
  img {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    border-color: ${(p) => p.theme.colors.shades[800]};
  }
  ${(p) =>
    p.status === 'focus' &&
    css`
      box-shadow: 0 0 0 3px ${(p) => rgba(p.theme.colors.tabaccoBrown, 0.2)};
      border-color: ${(p) => p.theme.colors.shades[800]};
    `}
  ${(p) =>
    p.status === 'error' &&
    css`
      border-color: ${p.theme.colors.error};
      &:focus {
        box-shadow: 0 0 0 3px ${(p) => p.theme.colors.errorLight};
      }
    `}
`

type Props = InputHTMLAttributes<HTMLInputElement> & {
  onChange: (value: string) => void
  onBlur: () => void
  isDateBlocked?: (date: Date) => boolean
  value: string
  status?: 'idle' | 'error'
  errorMsg?: string
  label?: string
}

const DatepickerInput: FC<Props> = ({
  onChange,
  value,
  errorMsg,
  status,
  label,
  isDateBlocked,
  onBlur,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation('other')
  const ref = useRef<HTMLElement>()

  useEffect(() => {
    const handleClick = (e) => {
      if (!ref.current.contains(e.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })

  return (
    <Downshift>
      {({ getRootProps, getInputProps, getMenuProps }) => {
        return (
          <Box
            {...getRootProps()}
            position="relative"
            display="block"
            ref={ref}
            as="section"
            mb="1rem"
          >
            <DatepickerButton
              role="button"
              type="button"
              onBlur={() => onBlur()}
              onClick={() => {
                setIsOpen(!isOpen)
              }}
              isFilled={value !== ''}
              status={errorMsg ? 'error' : isOpen ? 'focus' : status}
            >
              {value ? value : label ? label : t('preferredDate')}

              <img src="/images/calendar.svg" alt="calendar" />
            </DatepickerButton>
            {errorMsg && (
              <Text as="div" variant="micro" mt=".5rem" mb={0} color="red">
                {errorMsg}
              </Text>
            )}
            <Menu
              width="100%"
              {...getMenuProps()}
              bottom={errorMsg ? '26px' : '1px'}
              display={isOpen ? 'block' : 'none'}
            >
              <Datepicker
                value={value}
                isDateBlocked={isDateBlocked}
                onSetDate={(date) => {
                  onChange(format(date, 'dd-MM-yyyy'))
                  setIsOpen(false)
                }}
              />
            </Menu>
          </Box>
        )
      }}
    </Downshift>
  )
}

export default DatepickerInput
