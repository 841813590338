import { ContactPreference, LeadType } from '@generated'
import {
  emailRequired,
  genderRequired,
  lastNameRequired,
  messageRequired,
  phoneOptional,
} from '@lib/yup'
import * as Yup from 'yup'

export const schema = Yup.object().shape({
  type: Yup.string().oneOf([LeadType.Appointment, LeadType.Question]),
  gender: genderRequired,
  firstName: Yup.string(),
  lastName: lastNameRequired,
  email: emailRequired,
  phone: phoneOptional,
  contactPreference: Yup.string().oneOf([ContactPreference.Email, ContactPreference.Phone]),
  store: Yup.string().when('type', {
    is: LeadType.Appointment,
    then: (schema) => schema.required('forms.errors.store'),
  }),
  preferredDate: Yup.string().when('type', {
    is: LeadType.Appointment,
    then: (schema) => schema.required('forms.errors.date'),
  }),
  message: messageRequired,
  agree: Yup.bool().oneOf([true]),
  subscribe: Yup.boolean(),
})
