// @ts-strict-ignore
import Context from './Context'
import { useDay } from '@datepicker-react/hooks'
import { CleanButton, micro } from '@gassan-ui'
import { FC, useContext, useRef } from 'react'
import styled from 'styled-components'

const Button = styled(CleanButton)<{ isSelected: boolean; isCurrentDay: boolean }>`
  ${micro};
  font-size: 0.75rem;
  height: 2rem;
  width: 2rem;
  margin: 2px;
  border-radius: 100%;
  border: 1px solid transparent;
  color: ${(p) => p.theme.colors.shades[800]};
  transition: all 0.15s ease;
  position: relative;

  &:disabled {
    pointer-events: none;
    color: ${(p) => p.theme.colors.tabaccoLight};
    text-decoration: line-through;
  }

  &:hover {
    color: ${(p) => p.theme.colors.shades[800]};
    border-color: currentColor;
  }

  &:focus {
    border-color: currentColor;
  }

  ${(p) =>
    p.isSelected &&
    `
    color: ${p.theme.colors.tabaccoBrown}!important;
    border-color: currentColor;
  `}

  ${(p) =>
    p.isCurrentDay &&
    !p.isSelected &&
    `

    &:after {
      position: absolute;
      width: 0.75rem;
      background: currentColor;
      height: 1px;
      bottom: 6px;
      left:0;
      right: 0;
      margin: 0 auto;
      content: '';
    }
  `}
`

type Props = {
  date: Date
  dayLabel: string
  isSelected: boolean
  onSetDate: (date) => void
}

const Day: FC<Props> = ({ date, dayLabel, isSelected, onSetDate }) => {
  const dayRef = useRef(null)
  const today = new Date()
  const dateLabel = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear()
  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1))
  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
  } = useContext(Context)
  useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  })
  return (
    <Button
      onClick={onSetDate}
      onKeyDown={onSetDate}
      type="button"
      ref={dayRef}
      title={dateLabel}
      isSelected={isSelected}
      isCurrentDay={date.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)}
      disabled={date < yesterday || isDateBlocked(date)}
      tabIndex={-1}
    >
      {parseInt(dayLabel, 10)}
    </Button>
  )
}
export default Day
